.app-header {
    background-color: var(--navbar-bg); /* Semi-transparent navbar */
    border-bottom: 1px solid var(--accent-color);
    padding: 15px 20px;
    position: sticky;
    top: 0;
    z-index: 1000;
    backdrop-filter: blur(10px); /* Adds a blur effect */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .nav-list {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 10px 0;
  }
  
  .nav-list li {
    margin: 0 15px;
  }
  
  .nav-list a {
    color: var(--accent-color);
    text-decoration: none;
    font-weight: 700;
    transition: color 0.3s ease;
  }
  
  .nav-list a:hover {
    color: #fff;
  }
  