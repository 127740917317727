:root {
  --primary-color: #1e1e2f;  /* Dark background */
  --secondary-color: #f5f5f7; /* Light text */
  --accent-color: #4a90e2;    /* Soft blue for highlights */
  --font-family: 'Poppins', sans-serif;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.app {
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: rgba(30, 30, 47, 0.8); /* Transparent navbar */
  backdrop-filter: blur(10px); /* Add blur for modern effect */
  border-bottom: 1px solid var(--accent-color);
  position: sticky;
  top: 0;
  z-index: 100;
}

.nav a {
  margin: 0 15px;
  color: var(--accent-color);
  text-decoration: none;
  font-weight: 700;
}

.nav a:hover {
  color: #fff;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url('logo.jpg');
  background-size: cover;
  background-position: center;
  color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); /* Shadow around the hero section */
}

.hero h1 {
  font-size: 4rem;
  letter-spacing: 1px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8); /* Text shadow */
}

.section {
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.1); /* Transparent background */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); /* Section shadow */
}

.button {
  padding: 10px 30px;
  background-color: var(--accent-color);
  color: white;
  border-radius: 25px;
  border: none;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); /* Button shadow */
}

.button:hover {
  background-color: #3b78c4;
  transform: scale(1.05); /* Button hover effect */
}
