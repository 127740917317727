.app-footer {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 20px;
    text-align: center;
    border-top: 1px solid var(--accent-color);
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
  }
  
  .app-footer p {
    margin: 0;
    font-size: 0.9rem;
  }
  