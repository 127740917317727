/* Base styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Inter', sans-serif; /* Ensure this is loaded via Google Fonts */
  background-color: #0d0d0d; /* Dark theme */
  color: #f0f0f0; /* Light text on dark background */
}

#root {
  height: 100%;
}

/* Smooth scrolling for a modern feel */
html {
  scroll-behavior: smooth;
}

/* Minimalistic reset */
*, *::before, *::after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
