.aboutSection {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--primary-color);
  padding: 20px;
}

.aboutContent {
  max-width: 800px;
  background: rgba(255, 255, 255, 0.1); /* Transparent background */
  padding: 40px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  backdrop-filter: blur(10px); /* Adds a blur effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.aboutContent:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
}

.aboutHeading {
  font-size: clamp(2rem, 5vw, 3rem);
  color: var(--accent-color);
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.aboutHeading::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color: var(--accent-color);
  margin: 10px auto 0;
  border-radius: 2px;
}

.aboutText {
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  line-height: 1.6;
  text-align: justify;
  margin-top: 0;
  color: var(--secondary-color);
}

.aboutText a {
  color: var(--accent-color);
  transition: color 0.3s ease;
}

.aboutText a:hover {
  color: #3b78c4;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .aboutContent {
    padding: 20px;
  }

  .aboutHeading {
    font-size: 2rem;
  }

  .aboutText {
    font-size: 1rem;
  }
}
